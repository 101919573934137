import * as React from "react";
import { Tooltip } from "antd";
import { URLS } from "../../config/RouterUrls";
import Title from "antd/lib/typography/Title";
import { Link } from "react-router-dom";
import { toString } from "lodash";
import {
  FaInstagram,
  FaWhatsapp,
  FaMobileAlt,
  FaFacebook,
} from "react-icons/fa";
import { BiCopyright } from "react-icons/bi";

const links = [
  {
    title: "Quick Links",
    links: [
      { link: URLS.about, title: "About Us" },
      { link: URLS.contact, title: "Contact Us" },
      { link: URLS.termsandcondition, title: "Terms & Condition" },
      { link: URLS.privacypolicy, title: "Privacy Policy" },
      { link: URLS.support, title: "Support" },
    ],
  },
  {
    title: "Collection",
    links: [
      { link: URLS.sareeAll, title: "Pure Chiffon Sarees" },
      { link: URLS.cottonFebric, title: "Cotton Fabric" },
      { link: URLS.muffler, title: "Mufflers" },
      { link: URLS.scarf, title: "Scarf Sets" },
    ],
  },
];
const iconSize = 40;
const socialLinks = [
  {
    tooltip: "Click to visit our Instagram page",
    link: "https://instagram.com/bbg_royals?utm_medium=copy_link",
    icon: <FaInstagram size={iconSize} />,
  },
  {
    tooltip: "Click to visit our Facebook page",
    link: "https://m.facebook.com/bbgsarees/",
    icon: <FaFacebook size={iconSize} />,
  },
  {
    tooltip: "Click to enquire on WhatsApp",
    link: "https://wa.me/919879833576",
    icon: <FaWhatsapp size={iconSize} />,
  },
  {
    tooltip: "Click to call us on Mobile",
    link: "tel:919879833576",
    icon: <FaMobileAlt size={iconSize} />,
  },
];

export const AppFooter = () => {
  return (
    <div className="container is-fluid px-3 pt-6 pb-2 has-background-white">
      <div className="columns is-multiline" style={{ width: "100%" }}>
        <div className="column is-mobile-full is-half-tablet is-one-quarter-desktop is-flex is-align-items-center is-justify-content-center">
          <figure className="image">
            <img
              src={`/logo-large.png`}
              alt="BBG Royals"
              style={{ width: "250px" }}
            />
          </figure>
        </div>
        {links.map((doc, key) => {
          return (
            <div
              className="column is-mobile-full is-half-tablet is-one-quarter-desktop h-100 is-flex is-align-items-center"
              key={key}
            >
              <div>
                <Title level={4} className="has-text-primary-dark">
                  {doc.title}
                </Title>
                <div className="mt-4">
                  {doc.links.map((link, key1) => {
                    return (
                      <Title
                        className="my-2"
                        level={5}
                        key={`${toString(key) + toString(key1)}`}
                      >
                        <Link className="pl-2 footer-link" to={link.link}>
                          {link.title}
                        </Link>
                      </Title>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
        <div className="column is-mobile-full is-half-tablet is-one-quarter-desktop h-100 is-flex is-align-items-center is-flex-direction-column">
          <div>
            <Title level={4} className="has-text-primary-dark mb-5">
              Connect with us on:
            </Title>
            {socialLinks.map((social, key) => {
              return (
                <Tooltip key={key} title={social.tooltip}>
                  <a
                    className="mx-3 has-text-primary-dark"
                    target="_blank"
                    rel="noreferrer"
                    href={social.link}
                  >
                    {social.icon}
                  </a>
                </Tooltip>
              );
            })}
          </div>
        </div>
      </div>
      <div className="is-flex is-justify-content-end">
        <p className="is-flex is-align-items-center">
          <BiCopyright />
          &nbsp;BBG Royals. All Rights Reserved!
        </p>
      </div>
    </div>
  );
};
