import axios from "axios";

export const baseApi = (url: string, config = {}, headers = {}) =>
  axios.create({
    baseURL: url,
    withCredentials: true,
    headers: {
      Authorization: `Token ${window.localStorage.getItem("token")}`,
      ...headers,
    },
    ...config,
  });
