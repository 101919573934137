export const config = {
  apiBase: `${process.env.REACT_APP_API_BASE}`,
  environment: `${process.env.NODE_ENV}`,
  google: {
    clientId: `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`,
    secret: `${process.env.REACT_APP_GOOGLE_CLIENT_SECRET}`,
  },
  staticUrl: "https://static.bbgroyals.com",
  razorpay: {
    keyId: `${process.env.REACT_APP_RAZORPAY_KEY}`,
    secret: `${process.env.REACT_APP_RAZORPAY_SECRET}`,
    scriptUrl: "https://checkout.razorpay.com/v1/checkout.js",
  },
};

export const axiosCfg = {
  headers: {
    authorization: `Token ${window.localStorage.getItem("token")}`,
  },
  withCredentials: true,
};
