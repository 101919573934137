import { URLS } from "./RouterUrls";
const noHeaderPaths: string[] = [URLS.login, URLS.signup];

const noFooterPaths: string[] = [URLS.login, URLS.signup];

export const withHeader = (path: string) => {
  if (noHeaderPaths.includes(path)) return false;
  return true;
};

export const withFooter = (path: string) => {
  if (noFooterPaths.includes(path)) return false;
  return true;
};
