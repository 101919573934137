const state = {
  userData: null,
  cartData: [],
  productData: [],
  wishlistData: [],
  filterColors: {},
  filterPrints: {},
  userAddressData: [],
};

export const apiReducer = (initialState = state, action: any) => {
  const { type, data } = action;
  switch (type) {
    case "UPDATE_USER_DATA":
      return { ...initialState, userData: data };
    case "UPDATE_CART_DATA":
      return { ...initialState, cartData: data };
    case "UPDATE_PRODUCT_DATA":
      return { ...initialState, productData: data };
    case "UPDATE_WISHLIST_DATA":
      return { ...initialState, wishlistData: data };
    case "UPDATE_ADDRESS_DATA":
      return { ...initialState, userAddressData: data };
    case "UPDATE_FILTER_COLORS":
      return { ...initialState, filterColors: data };
    case "UPDATE_FILTER_PRINTS":
      return { ...initialState, filterPrints: data };
    case "LOGOUT":
      return {
        ...initialState,
        userData: null,
        cartData: [],
      };
    default:
      return initialState;
  }
};
