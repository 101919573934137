import { config } from "./config";
const PUBLIC_URL = "/";
const apiBase = config.apiBase;

export const URLS = {
  home: PUBLIC_URL + "",
  login: PUBLIC_URL + "login",
  signup: PUBLIC_URL + "signup",
  profile: PUBLIC_URL + "profile",
  about: PUBLIC_URL + "about",
  contact: PUBLIC_URL + "contact",
  terms: PUBLIC_URL + "terms",
  faqs: PUBLIC_URL + "faqs",
  orderPolicy: PUBLIC_URL + "order-policy",
  support: PUBLIC_URL + "support",
  order: PUBLIC_URL + "orders",
  orderItem: PUBLIC_URL + "order-item",
  orderSummary: PUBLIC_URL + "order-summary",
  checkout: PUBLIC_URL + "checkout",

  backendUsers: `${apiBase}/users`,
  backendWishlist: `${apiBase}/wishlist`,
  backendCart: `${apiBase}/cart`,
  backendProducts: `${apiBase}/products`,
  backendOrders: `${apiBase}/orders`,
  // product urls
  sareeAll: PUBLIC_URL + "products/saree",
  sareeAnimal: PUBLIC_URL + "products/saree/animals",
  sareeBirds: PUBLIC_URL + "products/saree/birds",
  sareeFloral: PUBLIC_URL + "products/saree/florals",
  sareeFruit: PUBLIC_URL + "products/saree/fruits",
  sareeKota: PUBLIC_URL + "products/saree/kota-cotton",
  cottonFebric: PUBLIC_URL + "products/cotton-fabric/cotton-fabric",
  muffler: PUBLIC_URL + "products/mufflers/mufflers",
  scarf: PUBLIC_URL + "products/scarfs/scarfs",
  shirts: PUBLIC_URL + "products/shirts/shirts",
  products: PUBLIC_URL + "products/",
  bothProduct: PUBLIC_URL + "products/:superId/:categoryId",
  superProduct: PUBLIC_URL + "products/:superId",
  privacypolicy: PUBLIC_URL + "privacypolicy",
  termsandcondition: PUBLIC_URL + "termsandcondition",
};

export const apiUrls = {
  loginGoogle: apiBase + "/user/login/google",
  getUserData: apiBase + "/user/getUserData",
  loginEmail: apiBase + "/user/login/email",
  sendForgotPasswordLink: apiBase + "/user/forgot",
  addUserAddress: apiBase + "/user/add-address",
  removeUserAddress: apiBase + "/user/remove-address", // address id require in params
  getAllUserAddress: apiBase + "/user/get-all-address",
  logout: apiBase + "/user/logout",
  logoutAll: apiBase + "/user/logoutAll",
  getProducts: apiBase + "/product/get",
  getProductCount: apiBase + "/product/count",
  addToCart: apiBase + "/cart/add",
  getCart: apiBase + "/cart/get",
  removeFromCart: apiBase + "/cart/remove", // _id of item to be sent
  emptyCart: apiBase + "/cart/empty", // _id of item to be sent
  addToWishlist: apiBase + "/wishlist/add",
  removeFromWishlist: apiBase + "/wishlist/remove",
  createRazorpayOrder: apiBase + "/orders/create-razorpay-order",
  addOrder: apiBase + "/orders/add",
  getOrder: apiBase + "/orders/get",
  getOrderSummary: apiBase + "/orders/get-summary",
};
