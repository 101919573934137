import { URLS } from "../config/RouterUrls";
import { baseApi } from "../config/createBaseApi";

const CreateOrderApi = () => {
  const api = baseApi(URLS.backendOrders);

  return {
    createCheckout: (body) =>
      api.post("/checkout", body).then((res) => res.data),
    startPayment: (body) =>
      api.post("/start-payment", body).then((res) => res.data),
    getCheckout: (params) =>
      api.get("/checkout", { params }).then((res) => res.data),
    updateProductQuantity: (body) =>
      api.post("/update-quantity", body).then((res) => res.data),
    removeItem: (body) =>
      api.post("/remove-item", body).then((res) => res.data),
    addUserForOrder: (body) =>
      api.post("/create-user", body).then((res) => res.data),
    addUserAddress: (body) =>
      api.post("/address", body).then((res) => res.data),
    getAddresses: (params) =>
      api.get("/address", { params }).then((res) => res.data),
    setAddress: (body) =>
      api.post("/set-address", body).then((res) => res.data),
    updateAddress: (body) =>
      api.patch("/address", body).then((res) => res.data),
    removeAddress: (params) =>
      api.delete("/address", { params }).then((res) => res.data),
  };
};

const orderapi = CreateOrderApi();
export default orderapi;
