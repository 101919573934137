import * as React from "react";
import { useSelector } from "react-redux";

const Privacypolicy = () => {
  const ui = useSelector((state: any) => state.ui);
  document.title = "Privacy Policy | BBG Royals";
  return (
    <div className="container">
      <p
        style={{
          fontSize: ui.width >= 1300 ? "3.5rem" : "2rem",
        }}
        className="has-text-primary-dark has-text-centered my-4"
      >
        PRIVACY POLICY
      </p>
      <div>
        <p className="has-text-justified mx-4">
          BBG Royals Pvt. Ltd (hereafter referred to as "Big tree creatives Pvt.
          Ltd." or "The Company") and/or its affiliates are committed to protect
          your and your family's personal information when you are using
          www.bbgroyals.com. We want our services to be safe and secure for our
          users. Uniform practices for collecting, using, disclosing, storing,
          retaining, disposing, accessing, transferring or otherwise processing
          such information assists BBG Royals to process Personal Information
          fairly and appropriately, disclosing it and/or transferring it only
          under appropriate circumstances. This Privacy policy relates to our
          use of any personal information we collect from you on the website.
          This Privacy policy tells you how we use your personal information
          collected through our website. Please read this Privacy policy before
          using the website or submitting any personal information. This policy
          will be updated subject to any changes in information collection,
          activities performed or any applicable regulations. You are encouraged
          to review the Privacy policy whenever you visit www.bbgroyals.com to
          make sure that you understand how any personal information you provide
          will be used. Please note: The Privacy practices set forth in this
          Privacy policy are for www.bbgroyals.com only. If you link to other
          websites, please review those Privacy policies, which may be very
          different.
        </p>
      </div>
      <div>
        <p
          style={{
            fontSize: ui.width >= 1300 ? "1.4rem" : "1.2rem",
          }}
          className="has-text-primary-dark mt-5 mb-1 mx-4"
        >
          COLLECTION OF YOUR INFORMATION
        </p>
        <p className="has-text-justified mx-4">
          BBG Royals collects, processes, and retains information about you when
          you visit our www.bbgroyals.com. You may choose to provide us with
          information, such as your email address, company information, street
          address, telephone number, or other information, to access protected
          information on www.bbgroyals.com or so we can follow up with you after
          your visit. Personal Information may include, but is not limited to:
          <ul>
            <li>i. Your name</li>
            <li>ii. Email addresses</li>
            <li>iii. Telephone numbers</li>
            <li>iv. Country, City and State</li>
          </ul>
        </p>
      </div>
      <div>
        <p
          style={{
            fontSize: ui.width >= 1300 ? "1.4rem" : "1.2rem",
          }}
          className="has-text-primary-dark mt-5 mb-1 mx-4"
        >
          DATA STORAGE
        </p>
        <p className="has-text-justified mx-4">
          BBG Royals may transfer your information from www.bbgroyals.com to
          other databases and store it on BBG Royals or other supplier systems.
          BBG Royals ensures appropriate security controls while storing data on
          its or its suppliers' systems.
        </p>
      </div>
      <div>
        <p
          style={{
            fontSize: ui.width >= 1300 ? "1.4rem" : "1.2rem",
          }}
          className="has-text-primary-dark mt-5 mb-1 mx-4"
        >
          CHANGES TO THE PRIVACY POLICY
        </p>
        <p className="has-text-justified mx-4">
          This privacy policy sets out how BBG Royals uses and protects
          information that customers give us when they use this website. We are
          committed to ensure and protect your privacy. Should we ask you to
          provide certain information by which you can be identified when using
          this website, and then you can be assured that it will only be used in
          accordance with this privacy statement. BBG Royals may change this
          policy from time to time by updating this page. Kindly check this page
          from time to time to ensure that you are happy with the changes.
        </p>
      </div>
      <div>
        <p
          style={{
            fontSize: ui.width >= 1300 ? "1.4rem" : "1.2rem",
          }}
          className="has-text-primary-dark mt-5 mb-1 mx-4"
        >
          SECURITY
        </p>
        <p className="has-text-justified mx-4">
          We are committed to ensuring that your information is secure. In order
          to prevent unauthorised access or disclosure, we have put in place
          suitable physical, electronic and managerial procedures to safeguard
          and secure the information we collect online.
        </p>
      </div>
      <div className="mb-5">
        <p
          style={{
            fontSize: ui.width >= 1300 ? "1.4rem" : "1.2rem",
          }}
          className="has-text-primary-dark mt-5 mb-1 mx-4"
        >
          PRIVACY OF CONTACT INFORMATION
        </p>
        <p className="has-text-justified mx-4">
          If you have any questions regarding our Privacy Statement or if you
          need to update, change or remove information, you can do so by
          contacting us at <a href="tel:+ 91 9879833576">+ 91 9879833576</a> or
          by regular email addressed to:{" "}
          <a href="mailto:bbgroyalsahmedabad@gmail.com">
            bbgroyalsahmedabad@gmail.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default Privacypolicy;
