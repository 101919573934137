import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  notification,
} from "antd";
import { get, map } from "lodash";
import { useDispatch } from "react-redux";
import { SaveOutlined, DeleteOutlined } from "@ant-design/icons";
import orderapi from "../../apis/order";
import Title from "antd/es/typography/Title";
import { setUserData } from "../../actions/api";

export const Orderitems = ({ item, order, setOrder }) => {
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(get(item, "quantity"));

  const removeItems = () => {
    setLoading(true);
    orderapi
      .then((res) => {
        if (res.success) {
          notification.success({
            message: "The product has been removed from order!",
          });
          setOrder(res.order);
        } else {
          notification.error({ message: res.msg });
        }
      })
      .catch(() => {
        notification.error({
          message: "The product has been removed from order!",
        });
      })
      .finally(() => setLoading(false));
  };
  const updateQuantity = (v) => {
    setLoading(true);
    orderapi
      .updateProductQuantity({
        id: order._id,
        quantity: v,
        itemId: item._id,
      })
      .then((res) => {
        if (res.success) {
          notification.success({ message: "Product quantity updated!" });
          setQuantity(v);
        } else notification.warning({ message: res.msg });
      })
      .catch(() => {
        notification.error({
          success: false,
          msg: "Error while reaching server!",
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (item) {
      setQuantity(get(item, "quantity"));
    }
  }, [item]);

  return (
    <div className="my-2">
      <Card loading={loading}>
        <Row>
          <Col span={10}>
            <figure className="image">
              <img
                src={get(item, "product.featureImage")}
                alt={`${item.product.name} | BBG Royals`}
              />
            </figure>
          </Col>

          <Col span={14} className="p-3">
            <Title
              style={{ fontSize: "15px" }}
              level={5}
            >{`${item.product.name}`}</Title>
            <Select
              className="mr-2"
              value={quantity}
              onChange={updateQuantity}
              style={{ width: 100, textAlign: "left" }}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((val) => (
                <Select.Option key={val}>{val}</Select.Option>
              ))}
            </Select>
            <div className="is-flex">
              <Title style={{ fontSize: "15px" }} level={5}>
                &#8377;
                {`${
                  (item.product.price -
                    (item.product.price * item.product.currentDiscount) / 100) /
                  100
                }/-`}
              </Title>
              <Popconfirm
                title="Do you really want to remove product?"
                okText="Yes"
                okButtonProps={{ danger: true }}
                cancelButtonProps={{ type: "text" }}
                cancelText="No"
                onConfirm={removeItems}
              >
                <Button icon={<DeleteOutlined />} type="text" danger />
              </Popconfirm>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export const Billing = ({ order, setOrder, refresh, onNext = () => {} }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onFinish = (formData) => {
    if (!order.user) {
      setLoading(true);
      formData.orderNo = order.orderNo;
      orderapi
        .addUserForOrder(formData)
        .then((res) => {
          if (res.success) {
            window.localStorage.setItem("token", res.token);
            dispatch(setUserData(res.user));
            window.localStorage.setItem("token-type", "temporary");
            refresh();
            onNext();
          } else {
            notification.warning({ message: res.msg });
          }
        })
        .catch(() => {
          notification.error({ message: "Error while reaching server!" });
        })
        .finally(() => setLoading(false));
    } else {
      onNext();
    }
  };
  return (
    <div>
      <Row>
        <Col xs={0} xl={4}></Col>
        <Col xs={24} md={12} xl={8} className="p-3">
          <Card className="card-override">
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              requiredMark="optional"
              disabled={loading}
            >
              <Form.Item
                initialValue={get(order, "user.name")}
                label="Name"
                name="name"
                rules={[{ required: true, message: "Name is required!" }]}
              >
                <Input
                  disabled={!!get(order, "user.name")}
                  placeholder="Alan Doe"
                />
              </Form.Item>
              <Form.Item
                initialValue={get(order, "user.email")}
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Email is required!" },
                  { type: "email", message: "Please enter valid email!" },
                ]}
              >
                <Input
                  disabled={!!get(order, "user.email")}
                  placeholder="someone@example.com"
                />
              </Form.Item>
              <Form.Item
                initialValue={get(order, "user.mobile")}
                label="Mobile"
                name="mobile"
                rules={[
                  { required: true, message: "Mobile is required!" },
                  {
                    pattern: /^[0-9]{10}$/,
                    message: "Please enter valid mobile number!",
                  },
                ]}
              >
                <Input
                  disabled={!!get(order, "user.mobile")}
                  placeholder="9876543210"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  loading={loading}
                  htmlType="submit"
                  type="primary"
                  icon={<SaveOutlined />}
                >
                  Next
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col xs={24} md={12} xl={8} className="p-3">
          <Card title="Order Details" className="card-override">
            {map(get(order, "products", []), (item) => (
              <Orderitems item={item} order={order} setOrder={setOrder} />
            ))}
          </Card>
        </Col>
        <Col xs={0} xl={4}></Col>
      </Row>
    </div>
  );
};
