import React, { Suspense, useState } from "react";
import ReactDOM from "react-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { createStore } from "redux";
import { rootReducer } from "./reducers/root";
import "antd/dist/antd.less";
import "./scss/global.scss";
import { withFooter, withHeader } from "./config/LayoutManager";
import { AppHeader as Header } from "./components/header/header";
import { AppFooter as Footer } from "./components/footer/footer";
import { setWidth, setScroll, setHeight } from "./actions/ui";
import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import { URLS } from "./config/RouterUrls";
import { Loader } from "./components/extra/loading/loading";
import { getUserData } from "./apis/user";
import Privacypolicy from "./components/profile/privacypolicy/privacypolicy";
import Termsandcondition from "./components/termandcondition/termandcondition";
import Checkout from "./components/checkout";

const Home = React.lazy(() => import("./components/home/home"));
const About = React.lazy(() => import("./components/about/about"));
const Product = React.lazy(() => import("./components/product"));
const ContactUs = React.lazy(() => import("./components/contact"));
const Error = React.lazy(() => import("./components/extra/error/error"));
const Profile = React.lazy(() => import("./components/profile/profile"));
const Orders = React.lazy(() => import("./components/orders"));
const OrderItem = React.lazy(() => import("./components/orders/orderItem"));
const OrderSummary = React.lazy(
  () => import("./components/orders/order-summary")
);
const Wishlist = React.lazy(() => import("./components/drawers/wishlist"));
const Cart = React.lazy(() => import("./components/drawers/cart"));

const LayoutManager = (props: { children: React.ReactChild }) => {
  const path = useLocation().pathname;
  const isHeader = withHeader(path);
  const isFooter = withFooter(path);
  const dispatch = useDispatch();
  const navHeight = useSelector((state: any) => state.ui.navHeight);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [loading, setLoading] = useState(false);

  window.addEventListener("resize", () => {
    dispatch(setWidth());
    dispatch(setHeight());
  });
  window.addEventListener("scroll", () => {
    dispatch(setScroll());
  });

  const verifyLogin = React.useCallback(
    async () => {
      setLoading(true);
      let token: string | null = null;

      token = window.localStorage.getItem("token")
        ? window.localStorage.getItem("token")
        : null;
      if (token != null) {
        await getUserData(dispatch, token, setLoading);
      } else {
        window.localStorage.removeItem("token");
      }
      setLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, [path]);

  React.useEffect(() => {
    verifyLogin();
  }, [verifyLogin]);

  return (
    <Suspense fallback={<Loader inline />}>
      {loading ? (
        <Loader inline />
      ) : (
        <Layout style={{ backgroundColor: "#fafafa" }}>
          {isHeader && <Header />}
          <Cart />
          <Wishlist />

          <Content
            style={{
              width: "100vw",
              margin: 0,
              marginTop: isHeader ? navHeight : 0,
            }}
          >
            {props.children}
          </Content>
          {isFooter && <Footer />}
        </Layout>
      )}
    </Suspense>
  );
};

const Main = () => {
  return (
    <Provider store={createStore(rootReducer)}>
      <Router>
        <LayoutManager>
          <Routes>
            <Route path={URLS.home} element={<Home />} />
            <Route path={URLS.profile} element={<Profile />} />
            <Route path={URLS.about} element={<About />} />
            <Route path={URLS.contact} element={<ContactUs />} />
            <Route path={URLS.products} element={<Product />} />
            <Route path={URLS.bothProduct} element={<Product />} />
            <Route path={URLS.superProduct} element={<Product />} />
            <Route path={URLS.order} element={<Orders />} />
            <Route path={URLS.orderItem} element={<OrderItem />} />
            <Route path={URLS.orderSummary} element={<OrderSummary />} />
            <Route path={URLS.privacypolicy} element={<Privacypolicy />} />
            <Route path={URLS.checkout} element={<Checkout />} />
            <Route path={`${URLS.checkout}/:orderId`} element={<Checkout />} />
            <Route
              path={URLS.termsandcondition}
              element={<Termsandcondition />}
            />
            <Route path="*" element={<Error />} />
          </Routes>
        </LayoutManager>
      </Router>
    </Provider>
  );
};

ReactDOM.render(<Main />, document.getElementById("root"));
