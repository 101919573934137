import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Popconfirm,
  Card,
  Divider,
  notification,
  Collapse,
  Modal,
} from "antd";
import {
  SaveOutlined,
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { get, map } from "lodash";
import orderapi from "../../apis/order";
const { Panel } = Collapse;

export const EditAddress = ({ refresh, record, setRecord, open, setOpen }) => {
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const onCancel = () => {
    setOpen(false);
    setRecord(null);
  };

  useEffect(
    () => {
      if (record) {
        form.setFieldValue("line1", record.line1);
        form.setFieldValue("line2", record.line2);
        form.setFieldValue("street", record.street);
        form.setFieldValue("pincode", record.pincode);
        form.setFieldValue("area", record.area);
        form.setFieldValue("city", record.city);
        form.setFieldValue("state", record.state);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [record]
  );

  const onFinish = (formData) => {
    setLoading(true);
    formData.id = record._id;
    orderapi

      .updateAddress(formData)
      .then((res) => {
        if (res.success) {
          notification.success({ message: res.msg });
          refresh();
          form.resetFields();
        } else {
          notification.warning({ message: res.msg });
        }
      })
      .catch(() => {
        notification.error({ message: "Error while reaching server!" });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      okText="Update"
      okButtonProps={{ icon: <EditOutlined />, loading }}
      cancelButtonProps={{ loading, type: "text" }}
      onOk={() => form.submit()}
    >
      <Form
        layout="vertical"
        onFinish={onFinish}
        disabled={loading}
        form={form}
      >
        <Form.Item label="Line1" name="line1">
          <Input placeholder="16/18 Shop No 2, Tel Gali" />
        </Form.Item>
        <Form.Item label="Line2" name="line2">
          <Input placeholder="Mangala Devi Mandir" />
        </Form.Item>
        <Form.Item label="Street" name="street">
          <Input placeholder="E.g Apollo Street" />
        </Form.Item>
        <Form.Item label="Pincode" name="pincode">
          <Input placeholder="6 digits[0-9] code" />
        </Form.Item>
        <Form.Item label="Area" name="area">
          <Input placeholder="Tel Gali" />
        </Form.Item>
        <Form.Item label="Town/City" name="city">
          <Input placeholder="Mumbai" />
        </Form.Item>
        <Form.Item label="State" name="state">
          <Input placeholder="Maharastra" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export const SaveAddress = ({ order, refresh }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [items, setItems] = useState([]);
  const [editing, setEditing] = useState(false);
  const [selected, setSelected] = useState(null);
  const [record, setRecord] = useState(null);

  useEffect(() => {
    setSelected(get, (order, "address._id"));
  }, [order]);

  const onSelect = (address) => {
    if (selected !== address) {
      setLoading(true);
      orderapi
        .setAddress({ orderNo: get(order, "orderNo"), address })
        .then((res) => {
          if (res.success) {
            setSelected(address);
            refresh();
          } else {
            notification.warning({ message: res.msg });
          }
        })
        .catch(() => {
          notification.error({ message: "Error while reaching server!" });
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    setLoading(true);
    orderapi
      .getAddresses({ user: get(order, "user._id") })
      .then((res) => {
        if (res.success) {
          setItems(res.items);
          setError(null);
        } else {
          setItems([]);
          setError(res.message);
        }
      })
      .catch(() => {
        setItems([]);
        setError("error while reaching server!");
      })
      .finally(() => setLoading(false));
  }, [order]);

  const deleteAddress = (id) => {
    setLoading(true);
    orderapi
      .removeAddress({ id })
      .then((res) => {
        if (res.success) {
          notification.success({ message: "Address removed!" });
          setSelected(null);
          refresh();
        } else {
          notification.warning({ message: res.msg });
        }
      })
      .catch(() => {
        notification.error({ message: "Error while reaching server!" });
      })
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <EditAddress
        refresh={refresh}
        record={record}
        setRecord={setRecord}
        setOpen={setEditing}
        open={editing}
      />
      <Row>
        {map(items, (item) => (
          <Col xs={24} md={12} lg={12} className="p-3">
            <Card
              style={{
                border:
                  selected === item._id
                    ? "1px solid #1677ff66"
                    : "1px solid #f0f0f0",
              }}
              bodyStyle={{ padding: 0 }}
            >
              <div className="p-5">
                <p>
                  {get(item, "line1")}, {get(item, "line2")}
                  <br />
                  {get(item, "street")}, {get(item, "area")}
                  <br />
                  {get(item, "city")}, {get(item, "state")}
                  {" - "}
                  {get(item, "pincode")},{get(item, "country")}
                </p>
              </div>
              <Divider className="m-0" />
              <div className="is-flex p-5 is-justify-content-space-between">
                <div>
                  <Button
                    onClick={() => {
                      setRecord(item);
                      setEditing(true);
                    }}
                    type="link"
                    size="small"
                    icon={<EditOutlined />}
                  >
                    Edit
                  </Button>
                  <Popconfirm
                    title="Do you want to delete address?"
                    onConfirm={() => deleteAddress(item._id)}
                    okText="Delete"
                    okButtonProps={{ icon: <DeleteOutlined /> }}
                    cancelButtonProps={{ type: "text" }}
                  >
                    <Button
                      size="small"
                      icon={<DeleteOutlined />}
                      className="ml-2"
                      danger
                      type="text"
                    >
                      Remove
                    </Button>
                  </Popconfirm>
                </div>
                <div>
                  <Button
                    onClick={() => onSelect(item._id)}
                    size="small"
                    type={selected === item._id ? "primary" : "default"}
                    icon={selected === item._id ? <CheckOutlined /> : null}
                  >
                    {selected === item._id ? "Selected" : "Select"}
                  </Button>
                </div>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export const AddUserAddress = ({ refresh, order }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const Finish = (formData) => {
    formData.user = order.user._id;
    setLoading(true);
    orderapi
      .addUserAddress(formData)
      .then((res) => {
        if (res.success) {
          notification.success({ message: "Address added!" });
          refresh();

          form.resetFields();
        } else notification.warning({ message: res.msg });
      })
      .catch((err) => {
        console.log(err);
        notification.error({ message: "Error while reaching server!" });
      })
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <Form form={form} layout="vertical" onFinish={Finish} disabled={loading}>
        <Form.Item label="Line1" name="line1">
          <Input placeholder="16/18 Shop No 2, Tel Gali" />
        </Form.Item>
        <Form.Item label="Line2" name="line2">
          <Input placeholder="Mangala Devi Mandir" />
        </Form.Item>
        <Form.Item label="Street" name="street">
          <Input placeholder="E.g Apollo Street" />
        </Form.Item>
        <Form.Item label="Pincode" name="pincode">
          <Input placeholder="6 digits[0-9] code" />
        </Form.Item>
        <Form.Item label="Area" name="area">
          <Input placeholder="Tel Gali" />
        </Form.Item>
        <Row>
          <Col xs={24} lg={11}>
            <Form.Item label="Town/City" name="city">
              <Input placeholder="Mumbai" />
            </Form.Item>
          </Col>

          <Col xs={0} lg={2}></Col>
          <Col xs={24} lg={11}>
            <Form.Item label="State" name="state">
              <Input placeholder="Maharastra" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Country" name="country">
          <Input placeholder="India" />
        </Form.Item>
        <Form.Item>
          <Button
            htmlType="submit"
            type="primary"
            loading={loading}
            icon={<SaveOutlined />}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

const Address = ({ refresh, order }) => {
  const items = [
    {
      key: "1",
      label: (
        <span>
          <SaveOutlined className="mr-2" /> Saved Addresses
        </span>
      ),
      children: <SaveAddress refresh={refresh} order={order} />,
    },
    {
      key: "2",
      label: (
        <span>
          <PlusOutlined className="mr-2" /> Add new address
        </span>
      ),
      children: <AddUserAddress refresh={refresh} order={order} />,
    },
  ];
  return (
    <div className="container p-3">
      <Card title="Your Shipping Addresses" className="card-override">
        <Collapse accordion defaultActiveKey={[1]}>
          {map(items, (item) => (
            <Panel showArrow={false} key={item.key} header={item.label}>
              {item.children}
            </Panel>
          ))}
        </Collapse>
      </Card>
    </div>
  );
};

export default Address;
