import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import Address from "./address";
import Summery from "./summery";
import { Steps, notification } from "antd";
import { useBulmaLayout } from "../../hooks/layout";
import { Billing } from "./billing";
import orderapi from "../../apis/order";
import { get, map } from "lodash";

const STEP = { billing: 0, address: 1, summery: 2 };

const Checkout = () => {
  const { orderId } = useParams();
  const [loading, setLoading] = useState(false);
  const [checkout, setCheckout] = useState(null);
  const [error, setError] = useState(null);
  const [errorType, setErrorType] = useState(null);
  const [step, setStep] = useState(STEP.billing);
  const [renderKey, setRenderKey] = useState(Math.random());
  const { mobile, tablet } = useBulmaLayout();

  const refresh = () => setRenderKey(Math.random());

  const steps = useMemo(() => {
    return [
      { key: "billing", title: "Billing Info", content: Billing },
      { key: "address", title: "Address Info", content: Address },
      { key: "summery", title: "Order Summery", content: Summery },
    ];
  });
  const onNext = () => {
    if (step === 2) {
      notification.warning({ message: "You are already on last step!" });
    } else {
      setStep(step + 1);
    }
  };
  const onPrevious = () => {
    if (step === 0) {
      notification.warning({ message: "You are already on first step!" });
    } else {
      setStep(step - 1);
    }
  };

  useEffect(() => {
    setLoading(true);
    orderapi
      .getCheckout({ orderNo: orderId })
      .then((res) => {
        if (res.success) {
          const order = get(res, "order", {});
          setCheckout(order);
          if (order.user && order.address) setStep(STEP.summery);
          else if (order.user) setStep(STEP.address);
          setError(null);
          setErrorType(null);
        } else {
          setCheckout(null);
          setError(res.msg);
          setErrorType(res.error);
        }
      })
      .catch((err) => {
        setCheckout(null);
        setErrorType("catch");
        setError("Error while reaching server!");
      })
      .finally(() => setLoading(false));
  }, [orderId, renderKey]);

  const Component = steps[step].content;

  return (
    <div className="container">
      <Steps onChange={(number) => setStep(number)} current={step}>
        {map(steps, (step) => (
          <Steps.Step key={step.key} title={step.title}>
            {<step.content />}
          </Steps.Step>
        ))}
      </Steps>
      <div className="p-3 py-6">
        <Component
          setOrder={setCheckout}
          order={checkout}
          onNext={onNext}
          onPrevious={onPrevious}
          refresh={refresh}
        />
      </div>
    </div>
  );
};

export default Checkout;
