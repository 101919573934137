import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Popconfirm,
  Row,
  Select,
  Statistic,
  notification,
} from "antd";
import { map, get } from "lodash";
import orderapi from "../../apis/order";
import Title from "antd/es/typography/Title";
import { ArrowRightOutlined, DeleteOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { config } from "../../config/config";

const Orderitems = ({ item, order, setOrder, refresh }) => {
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(get(item, "quantity"));

  const removeItem = () => {
    setLoading(true);
    orderapi
      .removeItem({ id: order._id, itemId: item._id })
      .then((res) => {
        if (res.success) {
          notification.success({
            message: "The product has been removed from order!",
          });
          setOrder(res.order);
          refresh();
        } else {
          notification.warning({ message: res.msg });
        }
      })
      .catch(() => {
        notification.error({ message: "Error while reaching server!" });
      })
      .finally(() => setLoading(false));
  };

  const updateQuantity = (v) => {
    setLoading(true);
    orderapi
      .updateProductQuantity({
        id: order._id,
        quantity: v,
        itemId: item._id,
      })
      .then((res) => {
        if (res.success) {
          notification.success({ message: "Product quantity updated!" });
          setQuantity(v);
          refresh();
        } else notification.warning({ message: res.msg });
      })
      .catch((Err) => {
        notification.error({
          success: false,
          msg: "Error while reaching server!",
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (item) {
      setQuantity(get(item, "quantity"));
    }
  }, [item]);

  return (
    <div className="my-2">
      <Card
        loading={loading}
        style={{ border: "none", padding: 0, margin: 0 }}
        bodyStyle={{ padding: 0, margin: 0 }}
      >
        <Row>
          <Col span={4}>
            <figure className="image">
              <img
                src={get(item, "product.featureImage")}
                alt={`${item.product.name} | BRI Jewellery`}
              />
            </figure>
          </Col>
          <Col span={12} className="p-3">
            <Title
              style={{ fontSize: "15px" }}
              level={5}
            >{`${item.product.name}`}</Title>
            <Title level={5} className="my-0">
              <span>
                &#8377;{" "}
                {(get(item, "price", 0) -
                  (get(item, "price", 0) * get(item, "currentDiscount", 0)) /
                    100) /
                  100}
              </span>
            </Title>
          </Col>

          <Col span={8} className="p-3 has-text-right">
            <Select
              className="mr-2"
              value={quantity}
              onChange={updateQuantity}
              style={{ width: 100, textAlign: "left" }}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((val) => (
                <Select.Option key={val}>{val}</Select.Option>
              ))}
            </Select>
            <Popconfirm
              title="Do you really want to remove product?"
              okText="Yes"
              okButtonProps={{ danger: true }}
              cancelButtonProps={{ type: "text" }}
              cancelText="No"
              onConfirm={removeItem}
            >
              <Button icon={<DeleteOutlined />} type="text" danger />
            </Popconfirm>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

const Summery = ({ order, setOrder, refresh }) => {
  const { orderId } = useParams();
  const [loading, setLoading] = useState(false);

  const startPayment = () => {
    setLoading(true);
    orderapi
      .startPayment({ orderNo: orderId })
      .then((res) => {
        if (res.success) {
          const html = `
            <body>
              <form method="POST" action="https://api.razorpay.com/v1/checkout/embedded">
                <input type="hidden" name="key_id" value="${res.key}"/>
                <input type="hidden" name="order_id" value="${res.order}"/>
                <input type="hidden" name="name" value="BBG Royals"/>
                <input type="hidden" name="description" value="BBG Royals Website Purchase"/>
                <input type="hidden" name="image" value="${
                  config.environment === "development"
                    ? "http://localhost:3000"
                    : "https://bbgroyals.vercel.app"
                }/logo-large.png"/>
                <input type="hidden" name="prefill[name]" value="${res.name}"/>
                <input type="hidden" name="prefill[contact]" value="${
                  res.mobile
                }"/>
                <input type="hidden" name="prefill[email]" value="${
                  res.email
                }"/>
                <input type="hidden" name="callback_url" value="${
                  config.environment === "development"
                    ? "http://localhost:4040"
                    : "https://api.bbgroyals.com"
                }/orders/complete-payment"/>
                <input type="hidden" name="cancel_url" value="${
                  config.environment === "development"
                    ? "http://localhost:4040"
                    : "https://api.bbgroyals.com"
                }/orders/cancel-payment"/>
                <button type="submit" id="submit-button" style="display:none">Submit</button>
              </form>
            </body>
          `;
          document.getElementById("form-div").innerHTML = html;
          document.getElementById("submit-button").click();
        } else notification.warning({ message: res.msg });
      })
      .catch((err) => {
        console.log(err);
        notification.error({ message: "Error while reaching server!" });
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="container p-3">
      <div id="form-div"></div>
      <Row>
        <Col xs={24} md={12} className="p-3">
          <Card title="Billing Information" className="card-override">
            <Statistic
              valueStyle={{ fontSize: 16, fontWeight: 500 }}
              className="my-3"
              title="Name"
              value={get(order, "user.name")}
            />
            <Statistic
              valueStyle={{ fontSize: 16, fontWeight: 500 }}
              className="my-3"
              title="Email"
              value={get(order, "user.email")}
            />
            <Statistic
              valueStyle={{ fontSize: 16, fontWeight: 500 }}
              className="my-3"
              title="Mobile"
              groupSeparator=""
              value={get(order, "user.mobile")}
            />
          </Card>
        </Col>
        <Col xs={24} md={12} className="p-3">
          <Card title="Address Information" className="card-override">
            <Statistic
              valueStyle={{
                fontSize: 16,
                fontWeight: 500,
                marginInlineStart: 0,
              }}
              className="my-3"
              title="Address"
              valueRender={() => (
                <span>
                  {get(order, "address.line1")},<br />
                  {get(order, "address.line2") && (
                    <span>
                      {get(order, "address.line2")},<br />
                    </span>
                  )}
                  {get(order, "address.street")},<br />
                  {get(order, "address.area")},<br />
                  {get(order, "address.city")},<br />
                  {get(order, "address.state")} -{" "}
                  {get(order, "address.pincode")},<br />
                  {get(order, "address.country")}
                </span>
              )}
            />
          </Card>
        </Col>
        <Col xs={24} md={12} className="p-3">
          <Card title="Product Information" className="card-override">
            {map(get(order, "products", []), (item) => (
              <Orderitems
                refresh={refresh}
                key={item._id}
                item={item}
                order={order}
                setOrder={setOrder}
              />
            ))}
          </Card>
        </Col>
        <Col xs={24} md={12} className="p-3">
          <Card
            title="Payment Information"
            className="card-override"
            style={{ height: "100%" }}
          >
            <div className="is-flex is-justify-content-space-between my-1">
              <Title className="m-0" level={5} style={{ fontSize: 16 }}>
                Order total
              </Title>
              <p style={{ fontSize: 16 }}>
                &#8377; {get(order, "total") / 100}
              </p>
            </div>
            <div className="is-flex is-justify-content-space-between my-1">
              <Title className="m-0" level={5} style={{ fontSize: 16 }}>
                Delivery Charges
              </Title>
              <p style={{ fontSize: 16 }}>
                &#8377; {get(order, "deliveryCharges")}
              </p>
            </div>
            {get(order, "appliedDiscount") && (
              <div className="is-flex is-justify-content-space-between my-1">
                <Title className="m-0" level={5} style={{ fontSize: 16 }}>
                  Discount
                </Title>
                <p style={{ fontSize: 16 }}>
                  - &#8377; {get(order, "appliedDiscount")}%
                </p>
              </div>
            )}
            <Divider />
            <div className="is-flex is-justify-content-space-between my-1">
              <Title className="m-0" level={5} style={{ fontSize: 16 }}>
                Final Amount
              </Title>
              <p style={{ fontSize: 16 }}>
                &#8377; {get(order, "finalAmount", 0) / 100}
              </p>
            </div>
            <div className="is-flex is-justify-content-center mt-4">
              <Button
                type="primary"
                icon={<ArrowRightOutlined />}
                loading={loading}
                onClick={startPayment}
              >
                Pay Now
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Summery;
