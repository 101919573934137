import * as React from "react";
import "./login.scss";
import { FaEnvelope, FaGoogle, FaKey } from "react-icons/fa";
import { Button, Form, message, Tooltip, Modal } from "antd";
import GoogleLogin from "react-google-login";
import { axiosCfg, config } from "../../config/config";
import { apiUrls } from "../../config/RouterUrls";
import Input from "antd/lib/input/Input";
import Password from "antd/lib/input/Password";
import axios from "axios";
import Cookies from "universal-cookie";
import { useDispatch } from "react-redux";
import { setLoggedIn } from "../../actions/ui";
import { Loader } from "../extra/loading/loading";
import Title from "antd/lib/typography/Title";
import { getUserData } from "../../apis/user";

const cookies = new Cookies();
const { useForm } = Form;

const Login = (props: { show: boolean; onClose: () => void }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [form] = useForm();

  const loginButton = async (formData: any) => {
    setLoading(true);
    await axios
      .post(apiUrls.loginEmail, formData, axiosCfg)
      .then(async (res) => {
        if (res.data.success) {
          console.log(res.data.token);
          message.success("Login Successful");
          dispatch(setLoggedIn(true));
          window.localStorage.setItem("token", res.data.token);
          window.location.reload();
          await getUserData(dispatch, res.data.token, setLoading);
          props.onClose();
        } else {
          message.error(res.data.message);
        }
      });
    setLoading(false);
  };

  const onLoginSuccess = async (response: any) => {
    setLoading(true);
    await fetch(apiUrls.loginGoogle, {
      method: "POST",
      body: JSON.stringify({
        token: response.tokenId,
      }),
      headers: {
        "content-type": "application/json",
      },
      credentials: "include",
    })
      .then(async (res) => {
        if (res.status === 200) {
          const data = await res.json();
          window.localStorage.setItem("token", data.token);
          window.location.reload();
          await getUserData(dispatch, data.token, setLoading);
          dispatch(setLoggedIn(true));
          message.success(" Login Successful");
          props.onClose();
        } else if (res.status === 403) {
          message.error(" Unexpected Error occured");
        } else {
          message.error(" Something went wrong!");
        }
      })
      .catch((err) => {
        console.log(err);
        message.error(" Something went wrong!");
      });
    setLoading(false);
  };

  const onLoginFailed = (e: any) => {
    setLoading(false);
    message.error("Error while Sign in with google, please try again later");
  };

  return (
    <Modal
      title={<Title level={5}>Login</Title>}
      visible={props.show}
      onCancel={props.onClose}
      footer={
        <div className="has-text-centered">
          <Tooltip title="Login with Email">
            <Button
              loading={loading}
              className="ml-1 mb-1 button is-primary"
              onClick={() => {
                form.submit();
              }}
            >
              Login
            </Button>
          </Tooltip>
          <Tooltip title="Close Login Popup">
            <Button
              className="ml-1 mb-1 button is-danger"
              onClick={() => {
                props.onClose();
              }}
            >
              Cancel
            </Button>
          </Tooltip>
          <GoogleLogin
            clientId={config.google.clientId}
            render={(renderProps) => (
              <Tooltip title="Sign in with Google">
                <Button
                  loading={loading}
                  className="button ml-1 mb-1 is-warning has-text-weight-bold"
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  htmlType="button"
                  icon={<FaGoogle size={24} className="mr-2" />}
                >
                  With Google
                </Button>
              </Tooltip>
            )}
            buttonText="Login"
            onSuccess={onLoginSuccess}
            onFailure={onLoginFailed}
            onRequest={() => {
              setLoading(true);
            }}
            cookiePolicy={"single_host_origin"}
          />
        </div>
      }
    >
      {loading ? (
        <Loader inline />
      ) : (
        // <div className="wrapper">
        //   <div className="login-container">
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 20,
            }}
          >
            <figure className="image">
              <img
                src="/logo-large.png"
                alt="404 - Not Found"
                style={{ width: "125px" }}
              />
            </figure>
          </div>
          <Form
            form={form}
            name="basic"
            initialValues={{ remember: true }}
            autoComplete="on"
            onFinish={loginButton}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please Enter Valid Email!",
                },
              ]}
            >
              <Input
                size="large"
                prefix={<FaEnvelope className="site-form-item-icon " />}
                placeholder="Enter your email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Password is required!",
                },
              ]}
            >
              <Password
                size="large"
                prefix={<FaKey className="site-form-item-icon " />}
                placeholder="Password"
                type="password"
                autoComplete="on"
              />
            </Form.Item>
            {/* <Form.Item>
              <Button
                className="button is-warning has-text-weight-bold"
                htmlType="submit"
                icon={<FaUnlock className="mr-2" />}
              >
                Login
              </Button>
            </Form.Item> */}

            {/* <Tooltip title="To update / reset password">
                <ForgotPasswordModal />
              </Tooltip> */}
          </Form>
        </>
        //   </div>
        // </div>
      )}
    </Modal>
  );
};

export default Login;
