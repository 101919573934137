const hour = new Date().getHours();
const dark = hour > 18 || hour < 7 ? true : false;

const state = {
  width: window.innerWidth,
  dark,
  height: window.innerHeight,
  scroll: window.scrollY,
  navHeight: 65,
  cartVisible: false,
  wishlistVisible: false,
  loggedIn: false,
  currentProductCount: 12,
  cartLoading: false,
  productLoading: false,
  showProductFilter: false,
  wishlistLoading: false,
  orderItems: [],
};

export const uiReducer = (initialState = state, action: any) => {
  switch (action.type) {
    case "setWidth":
      return { ...initialState, width: window.innerWidth };
    case "setDark":
      return { ...initialState, dark: action.yes };
    case "setHeight":
      return { ...initialState, height: window.innerHeight };
    case "setScroll":
      return { ...initialState, scroll: window.scrollY };
    case "setNavHeight":
      return { ...initialState, navHeight: action.height };
    case "setCartVisible":
      return { ...initialState, cartVisible: action.yes };
    case "setWishlistVisible":
      return { ...initialState, wishlistVisible: action.yes };
    case "setCartLoading":
      return { ...initialState, cartLoading: action.yes };
    case "setWishlistLoading":
      return { ...initialState, wishlistLoading: action.yes };
    case "setLoggedIn":
      return { ...initialState, loggedIn: action.yes };
    case "setCurrentProductCount":
      return { ...initialState, currentProductCount: action.count };
    case "setProductLoading":
      return { ...initialState, productLoading: action.yes };
    case "setShowProductFilter":
      return { ...initialState, showProductFilter: action.yes };
    case "setOrderItems":
      return { ...initialState, orderItems: action.items };
    default:
      return initialState;
  }
};
