export const setWidth = () => ({
  type: "setWidth",
});

export const setDark = (yes: boolean) => ({
  type: "setDark",
  yes,
});

export const setHeight = () => ({
  type: "setHeight",
});

export const setScroll = () => ({
  type: "setScroll",
});

export const setNavHeight = (height: number) => ({
  type: "setNavHeight",
  height,
});

export const setCart = (yes: boolean) => ({
  type: "setCartVisible",
  yes,
});

export const setWishlistVisible = (yes: boolean) => ({
  type: "setWishlistVisible",
  yes,
});

export const setCartLoading = (yes: boolean) => ({
  type: "setCartLoading",
  yes,
});

export const setWishlistLoading = (yes: boolean) => ({
  type: "setWishlistLoading",
  yes,
});

export const setLoggedIn = (yes: boolean) => ({
  type: "setLoggedIn",
  yes,
});

export const setCurrentProductCount = (count: number) => ({
  type: "setCurrentProductCount",
  count,
});

export const setProductLoading = (yes: boolean) => ({
  type: "setProductLoading",
  yes,
});

export const setShowProductFilter = (yes: boolean) => ({
  type: "setShowProductFilter",
  yes,
});

export const setOrderItems = (items: any[]) => ({
  type: "setOrderItems",
  items,
});

export const updateAppLoading = (data: any) => ({
  type: "UserDataLoading",
  data,
});
