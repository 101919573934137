import * as React from "react";
import { useSelector } from "react-redux";

const Termsandcondition = () => {
  const ui = useSelector((state: any) => state.ui);
  document.title = "Terms And Condition | BBG Royals";
  return (
    <div className="container">
      <p
        style={{
          fontSize: ui.width >= 1300 ? "3.5rem" : "2rem",
        }}
        className="has-text-primary-dark has-text-centered my-4"
      >
        TERMS AND CONDITIONS
      </p>
      <div>
        <p
          style={{
            fontSize: ui.width >= 1300 ? "1.4rem" : "1.2rem",
          }}
          className="has-text-primary-dark mt-5 mb-1 mx-4"
        >
          INTRODUCTION
        </p>
        <p className="has-text-justified mx-4">
          The domain name bbgroyals.com is registered in the name of BBG Royals
          Pvt. Ltd. which is a registered company. BBG Royals is inherited by
          Big tree creatives Pvt. Ltd.. Its registered office is at B-203,
          Emerald Residency, 10 Swastik Society, Navrangpura, Ahmedabad- 380009,
          Gujarat, India. BBG Royals is in the business of selling products such
          as saree with different print like bird, animal, floral, mufflers and
          scarf and has retail stores located in Ahmedabad.
        </p>
      </div>
      <div>
        <p
          style={{
            fontSize: ui.width >= 1300 ? "1.4rem" : "1.2rem",
          }}
          className="has-text-primary-dark mt-5 mb-1 mx-4"
        >
          COPYRIGHT
        </p>
        <p className="has-text-justified mx-4">
          All copyright, trademarks and all other intellectual property rights
          in the Website and its content (including without limitation the
          Website design, text, graphics and all software and source codes
          connected with the Website) are owned by or licensed to BBG Royals or
          otherwise used by BBG Royals as permitted by law.
        </p>
        <p className="has-text-justified mx-4">
          In accessing the Website you agree that you will access the content
          solely for your personal, non-commercial use. None of the content may
          be downloaded, copied, reproduced, transmitted, stored, sold or
          distributed without the prior written consent of the copyright holder.
          This excludes the downloading, copying and/or printing of pages of the
          Website for personal, non-commercial home use only. Certain screen
          layouts and graphics contained in this Site that are not owned by BBG
          Royal exclusively licensed to BBgroyals. No material from this site
          may be copied, modified, reproduced, republished, uploaded,
          transmitted, posted or distributed in any form without prior written
          permission from BBG Royals.
        </p>
      </div>
      <div>
        <p
          style={{
            fontSize: ui.width >= 1300 ? "1.4rem" : "1.2rem",
          }}
          className="has-text-primary-dark mt-5 mb-1 mx-4"
        >
          GOVERNING LAW
        </p>
        <p className="has-text-justified mx-4">
          These Terms and Conditions shall be governed by and construed in
          accordance with the law of India and you hereby submit to the
          exclusive jurisdiction of the Ahmedabad High Court.
        </p>
      </div>
      <div className="mb-5">
        <p
          style={{
            fontSize: ui.width >= 1300 ? "1.4rem" : "1.2rem",
          }}
          className="has-text-primary-dark mt-5 mb-1 mx-4"
        >
          NOTICE
        </p>
        <p className="has-text-justified mx-4">
          Please Read these Terms of Use carefully. By accessing this website
          and its pages, you agree to be bound by these Terms of Use, as they
          may be modified from time to time and posted on this site by
          BBgroyals.
        </p>
      </div>
      <div>
        <p
          style={{
            fontSize: ui.width >= 1300 ? "1.4rem" : "1.2rem",
          }}
          className="has-text-primary-dark mt-5 mb-1 mx-4"
        >
          RETURNS POLICY
        </p>
        <p className="has-text-justified mx-4">
          BBG Royals shall make all endeavours to deliver defect free products
          to the purchasers. BBG Royals does not take title to any returned
          items purchased by the user unless the item is received by bbgroyals.
          Any item purchased on our website does not qualify for any return
          unless the product delivered is damaged or has manufacturing defects.
        </p>
      </div>
      <div>
        <p
          style={{
            fontSize: ui.width >= 1300 ? "1.4rem" : "1.2rem",
          }}
          className="has-text-primary-dark mt-5 mb-1 mx-4"
        >
          REFUNDS POLICY
        </p>
        <p className="has-text-justified mx-4">
          The defective and/or damaged goods so received shall be communicated
          to BBG Royals within 5 to 7 days of its receipt. Any communication
          received after 5 to 7 days of delivery shall not qualify for return
          unless expressly covered by the product warranty even in case the said
          product has been wrongly delivered. BBG Royals holds the sole
          discretion to determine whether a refund can be issued.
        </p>
      </div>
      <div>
        <p
          style={{
            fontSize: ui.width >= 1300 ? "1.4rem" : "1.2rem",
          }}
          className="has-text-primary-dark mt-5 mb-1 mx-4 is-uppercase"
        >
          order policy
        </p>
        <p className="has-text-justified mx-4">
          In case your order is combined with other active orders from your
          account and is shipped together as a single shipment under one common
          tracking number, then cancelling one partial order/item would result
          in cancellation of all the other orders/items combined in the
          shipment. You can either accept the entire shipment or refuse it
          completely. If you refuse to accept the shipment, you will receive the
          complete refund once the shipment is returned to Amazon, if the
          payment was already made.
        </p>
      </div>
      <div>
        <p
          style={{
            fontSize: ui.width >= 1300 ? "1.4rem" : "1.2rem",
          }}
          className="has-text-primary-dark mt-5 mb-1 mx-4 is-uppercase"
        >
          shipping policy
        </p>
        <p className="has-text-justified mx-4">
          This Shipping & Delivery Policy is part of our Terms and Conditions
          ("Terms") and should be therefore read alongside our main Terms:
          In-store Pickups are available for all. We offer various shipping
          options and Order can be delivered within 5 to 7 days as per
          location*. In some cases a third-party supplier may be managing our
          inventory and will be responsible for shipping your products. We offer
          free shipping on all domestic orders. Please note, we may be subject
          to various rules and restrictions in relation to some international
          deliveries and you may be subject to additional taxes and duties over
          which we have no control. If such cases apply, you are responsible for
          complying with the laws applicable to the country where you live and
          will be responsible for any such additional costs or taxes.
        </p>
      </div>
    </div>
  );
};

export default Termsandcondition;
