import * as React from "react";
import { Divider, Menu, message } from "antd";
import { useNavigate } from "react-router-dom";
import { URLS } from "../../config/RouterUrls";
import {
  HeartOutlined,
  LogoutOutlined,
  ProfileOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { logout } from "../../apis/user";
import Cookies from "universal-cookie";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../actions/api";
import { setWishlistVisible } from "../../actions/ui";

const cookies = new Cookies();

export const ProfileActions = (props: {
  hidden: boolean;
  setHidden: (hidden: boolean) => void;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logOut = async () => {
    await logout();
    window.localStorage.removeItem("token");
    dispatch(logoutUser());
    message.warning("You are logged out");
    navigate(URLS.home);
  };

  return (
    <Menu hidden={props.hidden}>
      <Menu.Item
        onClick={() => {
          navigate(URLS.profile);
        }}
        icon={<SettingOutlined />}
      >
        My Account
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          navigate(URLS.order);
        }}
        icon={<ProfileOutlined />}
      >
        My Orders
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          props.setHidden(true);
          dispatch(setWishlistVisible(true));
        }}
        icon={<HeartOutlined />}
      >
        My Wishlist
      </Menu.Item>
      <Divider className="m-0" />
      <Menu.Item onClick={logOut} icon={<LogoutOutlined />}>
        Sign Out
      </Menu.Item>
    </Menu>
  );
};
