import * as React from "react";
import { Card, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

type LoaderProps = {
  inline?: boolean;
  text?: string;
  style?: React.CSSProperties;
};

export const Loader = (props: LoaderProps) => {
  const width = useSelector((state: any) => state.ui.width);
  return (
    <>
      {props.inline ? (
        <div
          style={{
            padding: 0,
            margin: 0,
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ...props.style,
          }}
        >
          <Spin indicator={<LoadingOutlined />} size="large" />
          <span className="ml-3 has-text-weight-bold">
            {props.text ? props.text : "Loading..."}
          </span>
        </div>
      ) : (
        <div
          style={{
            position: "fixed",
            padding: 0,
            margin: 0,
            top: 0,
            left: 0,
            zIndex: 999,
            width: "100vw",
            height: props.inline ? "auto" : "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#11111155",
            ...props.style,
          }}
        >
          <Card
            className="p-5"
            style={{
              width: width >= 1200 ? "40%" : width >= 768 ? "75%" : "90%",
              height: width >= 1200 ? "40%" : width >= 768 ? "75%" : "90%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <Spin indicator={<LoadingOutlined />} size="large" />
            <span
              className="m-0 ml-3 has-text-weight-bold"
              style={{
                fontSize: "1.5rem",
              }}
            >
              {props.text ? props.text : "Loading..."}
            </span>
          </Card>
        </div>
      )}
    </>
  );
};
