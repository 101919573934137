import axios from "axios";
import Cookies from "universal-cookie";
import { axiosCfg } from "../config/config";
import { apiUrls } from "../config/RouterUrls";
import {
  setUserData,
  setCartData,
  setWishlist,
  setUserAddresses,
} from "../actions/api";
import {
  setCartLoading,
  setCart,
  setWishlistLoading,
  setWishlistVisible,
} from "../actions/ui";
import { message } from "antd";

export const logout = async (all: boolean = false) => {
  let result = false;
  await axios
    .get(all ? apiUrls.logoutAll : apiUrls.logout, axiosCfg)
    .then((res) => {
      if (res.data.success) {
        result = true;
      }
    });
  return result;
};

export const getUserData = async (
  dispatch: any,
  token: any = null,
  setLoading: (state: boolean) => void
) => {
  await axios
    .get(
      apiUrls.getUserData,
      token
        ? {
            withCredentials: true,
            headers: {
              authorization: `Token ${token}`,
            },
          }
        : axiosCfg
    )
    .then((res) => {
      if (res.data.success === false) {
        setLoading(false);
        window.localStorage.removeItem("token");
      } else {
        dispatch(setUserData(res.data.user));
        dispatch(setCartData(res.data.cart));
        dispatch(setWishlist(res.data.wishlist));
        dispatch(setUserAddresses(res.data.addresses));
      }
    })
    .catch((err) => {
      setLoading(false);
      window.localStorage.removeItem("token");
    });
};

export const addItemToCart = async (
  UserData: any,
  dispatch: any,
  setAdding: any,
  product: any,
  CartData: any[]
) => {
  let result = false;
  setAdding(true);
  dispatch(setCartLoading(true));
  if (UserData) {
    await axios
      .post(
        `${apiUrls.addToCart}`,
        {
          product: product._id,
        },
        axiosCfg
      )
      .then((res) => {
        if (res.data.success) {
          dispatch(
            setCartData([
              ...CartData,
              {
                product: {
                  _id: product._id,
                  name: product.name,
                  price: product.price * 100,
                  featureImage: product.image,
                },
              },
            ])
          );
          result = true;
          message.success("Product added to cart!");
        }
      })
      .catch((err) => {
        message.error("Failed to add product!");
      });
  } else {
    dispatch(
      setCartData([
        ...CartData,
        {
          product: {
            _id: product._id,
            name: product.name,
            price: product.price * 100,
            featureImage: product.image,
          },
        },
      ])
    );
    message.success("Product added to cart!");
    result = true;
  }
  if (result) {
    dispatch(setCart(true));
  }
  setAdding(false);
  dispatch(setCartLoading(false));
  return result;
};

export const addToWishlist = async (
  dispatch: any,
  setAdding: any,
  product: any,
  Wishlist: any[]
) => {
  let result = false;
  setAdding(true);
  dispatch(setWishlistLoading(true));
  await axios
    .post(
      apiUrls.addToWishlist,
      {
        product: product._id,
      },
      axiosCfg
    )
    .then((res) => {
      if (res.data.success) {
        result = true;
        dispatch(
          setWishlist([
            ...Wishlist,
            {
              featureImage: product.image,
              otherImages: product.otherImages,
              _id: product._id,
              price: product.price * 100,
              name: product.name,
            },
          ])
        );
        dispatch(setWishlistVisible(true));
        message.success("Product added to wishlist");
      } else {
        message.error(res.data.msg);
      }
    })
    .catch((err) => {
      message.error("Error while adding!");
    });
  dispatch(setWishlistLoading(false));
  setAdding(false);
  return result;
};

export const addUserAddress = async (
  UserData: any = null,
  name: string,
  mobile: number,
  line1: string,
  line2: string,
  area: string,
  city: string,
  state: string,
  email: string | null = null,
  pincode: number,
  dispatch: any
) => {
  let result = false;
  await axios
    .post(
      apiUrls.addUserAddress,
      {
        id: UserData ? UserData._id : undefined,
        name,
        mobile,
        line1,
        line2,
        area,
        city,
        state,
        pincode,
        email,
      },
      axiosCfg
    )
    .then((res) => {
      if (res.data.success) {
        result = true;
        dispatch(setUserAddresses(res.data.addresses));
      }
    })
    .catch((err) => console.log(err));
  return result;
};

export const getAddresses = async (dispatch: any) => {
  let result = false;
  await axios
    .get(apiUrls.getAllUserAddress, axiosCfg)
    .then((res) => {
      if (res.data.success) {
        dispatch(setUserAddresses(res.data.addresses));
        result = true;
        message.success("Addresses are fetched!");
      } else {
        message.error("Addresses are not fetched!");
      }
    })
    .catch((err) => {
      message.error("Addresses are not fetched!");
    });
  return result;
};
