import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  setNavHeight,
  setCart,
  setCurrentProductCount,
} from "../../actions/ui";
import "./header.scss";
import { URLS } from "../../config/RouterUrls";
import { navConfig } from "../../config/navigation";
import { NavigationLinkType } from "../../types/header";
import { Collapse } from "react-collapse";
import { Button, Dropdown } from "antd";
import {
  CloseCircleFilled,
  MenuOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { setProducts } from "../../actions/api";
import Login from "../login/login";
import { ProfileActions } from "./profile-actions";

const NavBarEnd = () => {
  const dispatch = useDispatch();
  const UserData = useSelector((state: any) => state.api.userData);
  const CartData = useSelector((state: any) => state.api.cartData);
  const [login, setLogin] = React.useState(false);
  const [profileHidden, setProfileHidden] = React.useState(true);
  let name;
  if (UserData) {
    name = UserData.name.split(" ")[0];
  }

  return (
    <div className="navbar-end is-flex is-align-items-center is-flex-direction-row">
      {CartData.length > 0 && (
        <Button
          className="button has-text-primary-dark is-flex is-justify-content-center is-align-items-center is-flex-direction-column py-3 conditional-buttons"
          onClick={() => dispatch(setCart(true))}
        >
          <ShoppingCartOutlined />
          Cart
        </Button>
      )}
      {!UserData && (
        <>
          <Button
            className="button has-text-primary-dark is-flex is-justify-content-center is-align-items-center is-flex-direction-column py-3 conditional-buttons"
            onClick={() => {
              setLogin(true);
            }}
          >
            <UserOutlined />
            Sign In
          </Button>
          {login && <Login show={login} onClose={() => setLogin(false)} />}
        </>
      )}
      {UserData && (
        <Dropdown
          overlay={
            <ProfileActions
              hidden={profileHidden}
              setHidden={setProfileHidden}
            />
          }
        >
          <Button
            onClick={() => setProfileHidden(false)}
            onMouseEnter={() => setProfileHidden(false)}
            className="button is-flex has-text-primary-dark is-justify-content-center is-align-items-center is-flex-direction-column py-3 conditional-buttons"
          >
            <UserOutlined />
            Hi, {name}
          </Button>
        </Dropdown>
      )}
    </div>
  );
};

const SubItemsDropdown = (props: {
  item: NavigationLinkType;
  width: number;
  closeDrawer: () => void;
}) => {
  const [collapse, setCollapse] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const path = useLocation().pathname;
  return (
    <div className="navbar-item has-dropdown is-hoverable is-align-items-center">
      {props.width >= 1024 ? (
        <>
          <Button
            type="link"
            size="large"
            style={{
              fontSize: ".85rem",
            }}
            className="navbar-link is-flex is-align-items-center m-0 has-text-primary-dark"
          >
            {props.item.icon} &nbsp; {props.item.title}
          </Button>
          <div className="navbar-dropdown is-boxed" key={path}>
            {props.item.items &&
              props.item?.items.map((value, key) => {
                return (
                  <Button
                    type="link"
                    className="navbar-item has-text-primary-dark is-flex is-align-items-center"
                    key={key}
                    onClick={() => {
                      props.closeDrawer();
                      if (path !== value.link) {
                        dispatch(setProducts([]));
                        dispatch(setCurrentProductCount(20));
                        navigate(value.link);
                      }
                      window.scrollTo({ top: 0 });
                    }}
                    style={{
                      fontSize: ".85rem",
                    }}
                  >
                    {value.title}
                  </Button>
                );
              })}
          </div>
        </>
      ) : (
        <>
          <Button
            type="link"
            className="ml-2 navbar-item navbar-link has-text-primary-dark"
            onClick={() => {
              setCollapse(!collapse);
            }}
            style={{
              fontSize: ".85rem",
            }}
          >
            {props.item.title}
          </Button>
          <Collapse className="is-boxed" isOpened={collapse}>
            {props.item.items &&
              props.item?.items.map((value, key) => {
                return (
                  <Button
                    type="link"
                    className="navbar-item ml-3 has-text-primary-dark"
                    key={key}
                    onClick={() => {
                      props.closeDrawer();
                      if (path !== value.link) {
                        dispatch(setProducts([]));
                        dispatch(setCurrentProductCount(20));
                        navigate(value.link);
                      }
                      window.scrollTo({ top: 0 });
                    }}
                    style={{
                      fontSize: ".85rem",
                    }}
                  >
                    {value.title}
                  </Button>
                );
              })}
          </Collapse>
        </>
      )}
    </div>
  );
};

export const AppHeader = () => {
  const uiState = useSelector((state: any) => state.ui);
  const dispatch = useDispatch();
  const isScrolled = uiState.scroll > 0 ? true : false;
  const [drawer, setDrawer] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const path = useLocation().pathname;

  const handleScroll = (e: any) => {
    dispatch(setNavHeight(e.currentTarget.clientHeight));
  };
  return (
    <nav
      className={`navbar is-fixed-top ${isScrolled ? "scrolled" : "simple"}`}
      onScroll={handleScroll}
    >
      <div
        className={`navbar-brand p-0 ${
          uiState.width >= 1024 && "mr-6 "
        } is-flex is-align-items-center`}
      >
        <Link
          to={URLS.home}
          className="navbar-logo"
          onClick={() => {
            window.scrollTo({ top: 0 });
          }}
        >
          <img src="/logo.png" alt="BBG Royals" />
        </Link>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {uiState.width <= 1023 && <NavBarEnd />}
        </div>
        {uiState.width <= 1023 && (
          <Button
            className="button mr-2"
            onClick={() => {
              setDrawer(!drawer);
            }}
            style={{
              border: "none",
            }}
          >
            {!drawer ? <MenuOutlined /> : <CloseCircleFilled />}
          </Button>
        )}
      </div>
      <div className={`navbar-menu  ${drawer && "is-active"}`}>
        <div className="navbar-end is-align-items-center">
          {navConfig.map((navItem, key) => {
            return (
              <React.Fragment key={key}>
                {navItem.items ? (
                  <SubItemsDropdown
                    item={navItem}
                    width={uiState.width}
                    closeDrawer={() => {
                      setDrawer(false);
                    }}
                  />
                ) : (
                  <Button
                    type="link"
                    className="navbar-item is-flex is-align-items-center has-text-primary-dark"
                    onClick={() => {
                      setDrawer(false);
                      if (path !== navItem.link) {
                        dispatch(setProducts([]));
                        dispatch(setCurrentProductCount(20));
                        navigate(navItem.link);
                      }
                      window.scrollTo({ top: 0 });
                    }}
                    style={{
                      fontSize: ".85rem",
                    }}
                  >
                    {navItem.icon} &nbsp; {navItem.title}
                  </Button>
                )}
              </React.Fragment>
            );
          })}
        </div>
        {uiState.width >= 1024 && <NavBarEnd />}
      </div>
    </nav>
  );
};
