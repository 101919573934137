import { NavigationLinkType } from "../types/header";
import { URLS } from "./RouterUrls";

export const navConfig: NavigationLinkType[] = [
  // nav saree routes starts
  {
    title: "Pure Chiffon Sarees",
    link: URLS.sareeAll,
    items: [
      {
        title: "Animal Prints",
        link: URLS.sareeAnimal,
      },
      {
        title: "Birds",
        link: URLS.sareeBirds,
      },
      {
        title: "Florals",
        link: URLS.sareeFloral,
      },
      {
        title: "Fruits",
        link: URLS.sareeFruit,
      },
      {
        title: "Kota Cotton",
        link: URLS.sareeKota,
      },
    ],
  },
  // nav saree routes ends
  {
    title: "Cotton Fabric",
    link: URLS.cottonFebric,
  },
  // {
  //   title: "Twill Mufflers",
  //   link: URLS.muffler,
  // },
  // {
  //   title: "Silk Scarf Sets",
  //   link: URLS.scarf,
  // },
];
