export const setUserData = (data: any) => ({
  type: "UPDATE_USER_DATA",
  data,
});

export const setCartData = (data: any) => ({
  type: "UPDATE_CART_DATA",
  data,
});

export const setProducts = (data: any) => ({
  type: "UPDATE_PRODUCT_DATA",
  data,
});

export const setFilterColors = (data: any) => ({
  type: "UPDATE_FILTER_COLORS",
  data,
});

export const setFilterPrints = (data: any) => ({
  type: "UPDATE_FILTER_PRINTS",
  data,
});

export const setWishlist = (data: any) => ({
  type: "UPDATE_WISHLIST_DATA",
  data,
});

export const setUserAddresses = (data: any) => ({
  type: "UPDATE_ADDRESS_DATA",
  data,
});

export const logoutUser = () => ({
  type: "LOGOUT",
});
